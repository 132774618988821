import Api from 'Providers/apiProvider';

export default {
  getVendors: async (top = 10, skip = 0, filter = '') => {
    try {
      const data = await Api.get('/Vendors', {
        params: { skip, top, filter },
      });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getVendorCompanies: async (top = 10, skip = 0, systemCode, vendorNumber) => {
    try {
      const data = await Api.get(
        `/systems/${systemCode}/vendors/${vendorNumber}/vendorCompanies`,
        {
          params: { skip, top },
        }
      );
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getVendor: async (systemCode, vendorNumber) => {
    try {
      const data = await Api.get(`/Vendors/${systemCode}/${vendorNumber}`);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getVendorsBySystem: async (query, systemCode) => {
    try {
      const data = await Api.get(`/systems/${systemCode}/vendors`, {
        params: {
          search: query,
        },
      });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getVendorComments: async (systemCode, vendorNumber) => {
    try {
      const data = await Api.get(
        `/Vendors/${systemCode}:${vendorNumber}/comments`
      );
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  postVendorComment: async (systemCode, vendorNumber, comment) => {
    try {
      const data = await Api.post(
        `/Vendors/${systemCode}:${vendorNumber}/comments`,
        comment
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  deleteVendorComment: async (id) => {
    try {
      const data = await Api.delete(`/VendorComments/${id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  postVendor: async (vendor) => {
    try {
      const data = await Api.post(`/Vendors/`, vendor);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  putVendor: async (vendor) => {
    try {
      const data = await Api.put(`/Vendors/`, vendor);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  deleteVendor: async (vendor) => {
    try {
      const data = await Api.delete(
        `/Vendors/${vendor.systemCode}/${vendor.vendorNumber}`
      );
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  postBankDetail: async (bankDetail) => {
    try {
      const data = await Api.post(`/BankDetails`, bankDetail);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  putBankDetail: async (bankDetail) => {
    try {
      const data = await Api.put(`/BankDetails`, bankDetail);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  deleteBankDetail: async (bankDetail) => {
    try {
      const data = await Api.delete(
        `/BankDetails/${bankDetail.systemCode}/${bankDetail.vendorNumber}/${bankDetail.countryCode}/${bankDetail.bankKeys}/${bankDetail.bankAccountNumber}`
      );
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  postVendorCompany: async (vc) => {
    try {
      const data = await Api.post(`/VendorCompanies`, vc);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  putVendorCompany: async (vc) => {
    try {
      const data = await Api.put(`/VendorCompanies`, vc);
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  deleteVendorCompany: async (vc) => {
    try {
      const data = await Api.delete(
        `/VendorCompanies/${vc.systemCode}/${vc.companyCode}/${vc.vendorNumber}`
      );
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  restoreVendorCompany: async (vc) => {
    try {
      const data = await Api.patch(
        `/VendorCompanies/${vc.systemCode}/${vc.companyCode}/${vc.vendorNumber}`
      );
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getBankDetails: async (systemCode, vendorNumber) => {
    try {
      const data = await Api.get(`/BankDetails`, {
        params: {
          systemCode,
          vendorNumber,
        },
      });
      return data;
    } catch (error) {
      console.error('HTTP Error: ', error);
    }
  },
  getAttachments: async (systemCode, vendorNumber) => {
    try {
      const data = await Api.get(
        `/VendorAttachments/${systemCode}/${vendorNumber}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  postAttachments: async (systemCode, vendorNumber, files) => {
    try {
      const data = await Api.post(
        `/Vendors/${systemCode}:${vendorNumber}/attachments`,
        files,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  downloadAttachment: async (id) => {
    try {
      const data = await Api.get(`/VendorAttachments/${id}`, {
        responseType: 'blob',
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  deleteAttachment: async (id) => {
    try {
      const data = await Api.delete(`/VendorAttachments/${id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};
